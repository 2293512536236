export function makeDate(year, month, day) {
    day = day.toString(), month = month.toString()
    return [year.toString(), month.length === 1?"0"+month:month, day.length === 1?"0"+day:day].join("-")
}

export function today() {
    const today = new Date()
    return makeDate(today.getFullYear(), (today.getMonth() + 1), today.getDate())
}

export function addHours(time, hours){
    var newTime = new Date(today() + " " + time)
    newTime = new Date(newTime.getTime() + hours * 3600000)
    var newHours = newTime.getHours().toString()
    var newMinutes = newTime.getMinutes().toString()
    return (newHours.length === 1?'0'+newHours:newHours) + ":" + (newMinutes.length === 1?'0'+newMinutes:newMinutes)
}

export function monthNameList() {
    return [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
}

export function monthSelectList() {
    var items = [], monthName = monthNameList()
    for(var i = 1; i <= 12; i++){
        items.push({text: monthName[i-1], value: i.toString()})
    }
    return items
}

export function makeYearList(startYear, range=1) {
    var years = []
    const loops = range === 0?new Date().getFullYear() - startYear:range
    for(var i = 0; i<loops; i++){
        years.push({text: (startYear + i).toString(), value: (startYear + i).toString()})
    }
    return years
}

