export function copyObject(obj, excludes=[]) {
    var newObject = {}

    for (let key in obj) {
        if(!excludes.includes(key)) {
            newObject[key] = obj[key]
        }
    }

    return newObject
}

export function copyArray(array) {
    var newArray = []
    for(let index in array) {
        newArray.push(array[index])
    }
    return newArray
}


export function openNewTab(url) {
    window.open(url)
}

export function delay(func, timeout=3000) {
    setTimeout(() => {
        func()
    }, timeout)
}

export function computeGeoDistance(olat, olng, dlat, dlng) {
    const radius = 6371
    const latRadius = (dlat - olat) * Math.PI / 180
    const lngRadius = (dlng - olng) * Math.PI / 180
    const olatRadius = olat * Math.PI / 180
    const dlatRadius = dlat * Math.PI / 180
    var distance = Math.sin(latRadius / 2) * Math.sin(latRadius / 2) + Math.sin(lngRadius / 2) * Math.sin(lngRadius / 2) * Math.cos(olatRadius) * Math.cos(dlatRadius)
    distance = 2 * Math.atan2(Math.sqrt(distance), Math.sqrt(1 - distance))
    distance = distance * radius

    return Math.round(distance * 1000, 6)
}