<template>
    <div>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Create Promoter Account</p>
                <p class="text-title mb-0" v-if="!loading && !permissions.can_add">You do not have permission to create a promoter account</p>
            </v-col>
        </v-row>
        <v-form lazy-validation ref="form" v-model="valid" v-if="permissions.can_add">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-h6 mb-0">Basic Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-file-input ref="file" label="Profile Picture*" show-size accept="images/jpg, images/jpeg, images/png" :rules="rules.image" :error-messages="errors.image" @change="readFileData" v-model="object.photo"></v-file-input>
                    <small class="red--text">Note: Only accepts PNG, JPG, JPEG file (Less than 2 MB). Recommended dimension is 400x400.</small>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="NRIC First Name*" :rules="rules.first_name" :error-messages="errors.first_name" v-model="object.first_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="NRIC Last Name*" :rules="rules.last_name" :error-messages="errors.last_name" v-model="object.last_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Alias Name" :rules="rules.alias_name" :error-messages="errors.alias_name" v-model="object.alias_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="NRIC*" :rules="rules.nric" :error-messages="errors.nric" v-model="object.nric"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Email" :rules="rules.email" :error-messages="errors.email" v-model="object.email"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Gender*" :items="genders" :rules="rules.gender" :error-messages="errors.gender" v-model="object.gender"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-menu offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false" v-model="birthDateMenu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable readonly label="Date of Birth*" v-bind="attrs" v-on="on" :rules="rules.dob" :error-messages="errors.dob" v-model="object.dob"></v-text-field>
                        </template>
                        <v-date-picker no-title :min="getMinDOBDate()" :max="getMaxDOBDate()" v-model="object.dob" @input="birthDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Mobile Number*" :rules="rules.mobile_number" :error-messages="errors.mobile_number" v-model="object.mobile_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-autocomplete dense clearable label="Citizenship*" :items="citizenships" :rules="rules.citizenship" :error-messages="errors.citizenship" v-model="object.citizenship"></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-menu offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false" v-model="prDateMenu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable label="Date of PR" v-bind="attrs" v-on="on" :rules="rules.dopr" :error-messages="errors.dopr" v-model="object.dopr"></v-text-field>
                        </template>
                        <v-date-picker no-title :min="getMinDOBDate()" :max="getMaxDOPRDate()" v-model="object.dopr" @input="prDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-autocomplete dense clearable label="Choice of CPF*" :items="cpf_choices" :rules="rules.cpf_choice" :error-messages="errors.cpf_choice" v-model="object.cpf_choice"></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-autocomplete dense clearable label="Shirt Size*" :items="shirt_sizes" :rules="rules.shirt_size" :error-messages="errors.shirt_size" v-model="object.shirt_size"></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Agency*" :items="agencies" :rules="rules.agency" :error-messages="errors.agency" v-model="object.agency"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="F/G Type" :items="fg_types" :error-messages="errors.fg_type" v-model="object.fg_type"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Employment Status" :items="employment_statuses" :error-messages="errors.employment_status" v-model="object.employment_status"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-menu offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false" v-model="leaveDateMenu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable label="Date of Resign" v-bind="attrs" v-on="on" :rules="rules.dol" :error-messages="errors.dol" v-model="object.dol"></v-text-field>
                        </template>
                        <v-date-picker no-title color="primary" v-model="object.dol" @input="leaveDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox dense clearable label="SDL Payable" :error-messages="errors.sdl" v-model="object.sdl"></v-checkbox>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-h6 mb-0">Address Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete dense clearable label="District*" :items="districts" :rules="rules.district" :error-messages="errors.district" v-model="object.district"></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field dense clearable label="Block No." :error-messages="errors.block_number" v-model="object.block_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense clearable label="Street Name*" :rules="rules.street_name" :error-messages="errors.street_name" v-model="object.street_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Unit No." :error-messages="errors.unit_number" v-model="object.unit_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Building Name" :error-messages="errors.building_name" v-model="object.building_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense clearable label="Postal Code*" :rules="rules.postal_code" :error-messages="errors.postal_code" v-model="object.postal_code"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-h6 mb-0">Bank Info</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-autocomplete dense clearable label="Bank*" :items="banks" :rules="rules.bank" :error-messages="errors.bank" v-model="object.bank" @change="updateBranchCode"></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field dense clearable label="Branch Code*" :disabled="isDisabledBranch" :rules="rules.branch_code" :error-messages="errors.branch_code" v-model="object.branch_code"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field dense clearable label="Account No.*" :rules="rules.account_number" :error-messages="errors.account_number" v-model="object.account_number"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <small class="red--text">* Required field</small><br>
                    <small class="red--text">Note: Date of PR is required if the citizenship is PR.</small>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn color="primary" :loading="loading" @click="create">Create</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { makeDate, today } from '@/utils/dates'
import { encodeBase64 } from '@/utils/encodes'
import { copyObject } from '@/utils/helper'
import MessageNotifier from '../../../shared/MessageNotifier.vue'

export default {
	name: 'PromoterCreateForm',
    components: {
        messageNotifier: MessageNotifier,
    },
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			birthDateMenu: false,
			prDateMenu: false,
			leaveDateMenu: false,
			isDisabledBranch: false,
			errors: {},
			genders: [],
			citizenships: [],
			shirt_sizes: [],
			cpf_choices: [],
			districts: [],
			banks: [],
            agencies: [],
            employment_statuses: [],
            fg_types: [],
            permissions: {
                can_add: false
            },
			object: {
				first_name: '',
				last_name: '',
				alias_name: '',
				nric: '',
				email: '',
				mobile_number: '',
				dob: '',
				dopr: '',
				citizenship: '',
				district: '',
				block_number: '',
				street_name: '',
				unit_number: '',
				building_name: '',
				postal_code: '',
				bank: '',
				branch_code: '',
				account_number: '',
				cpf_choice: '',
				shirt_size: '',
				agency: '',
				employment_status: '',
				fg_type: '',
				sdl: '',
				dol: '',
				image: '',
			},
			rules: {
				first_name: [
					(value) => !!value || 'First name is required',
					(value) => value && value.length >= 1 && value.length <= 100 || 'First name must be between 1 and 100 characters'
				],
				last_name: [
					(value) => !!value || 'Last name is required',
					(value) => value && value.length >= 1 && value.length <= 100 || 'Last name must be between 1 and 100 characters'
				],
                alias_name: [
                    (value) => {
                        if(value && value.length < 1 || value.length > 100) {
                            return 'Alias name must be between 1 and 100 characters'
                        }

                        return true
                    }
                ],
				nric: [
                    (value) => !!value || 'NRIC is required',
					(value) => value && value.length == 9 || 'NRIC must be 9 characters'
				],
				email: [
					(value) => {
                        if(value && !/.+@.+\..+/.test(value)){
                            return 'Email is invalid'
                        }

                        return true
                    }
				],
				gender: [
					(value) => !!value || 'Gender is required'
				],
                agency: [
                    (value) => !!value || 'Agency is required'
                ],
				citizenship: [
					(value) => !!value || 'Citizenship is required'
				],
				dob: [
					(value) => !!value || 'Date of birth is required'
				],
				mobile_number: [
                    (value) => {
                        if(!value) {
                            return 'Mobile number is required'
                        }

                        if(value.length !== 8) {
                            return 'Postal code must be 8 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Mobile number must be 8 digits'
                        }

                        if(!['8', '9'].includes(value.substring(0, 1))) {
                            return 'Mobile number must starts with 8 or 9'
                        }

                        return true
					}
				],
				dopr: [
                    (value) => {
                        const citizenship = this.object.citizenship
                        if(!value && citizenship && citizenship.toLowerCase() === 'pr'){
                            return "Date of PR is required"
                        }

                        return true
                    }
				],
				district: [
					(value) => !!value || 'District is required'
				],
				street_name: [
					(value) => !!value || 'Street name is required'
				],
				postal_code: [
                    (value) => {
                        if(!value) {
                            return 'Postal code is required'
                        }

                        if(value.length !== 6) {
                            return 'Postal code must be 6 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Mobile number must be 6 digits'
                        }

                        return true
					}
				],
				bank: [
					(value) => !!value || 'Bank is required'
				],
				branch_code: [
                    (value) => {
                        if(this.object.bank){
                            const bank = this.banks.filter(item => item.value === this.object.bank)[0]
                            if(["DBS", "POSB", "OCBC", "UOB"].indexOf(bank.code) > -1){
                                this.isDisabledBranch = true
                                return true
                            }else{
                                this.isDisabledBranch = false
                            }
                        }

                        if(!value) {
                            return 'Branch code is required'
                        }

                        if(value.length < 3 || value.length > 10){
                            return 'Branch code must be between 3 and 10 characters'
                        }

                        if(!/^\d+{3, 10}$/.test(value)) {
                            return 'Branch code must be numeric value'
                        }

                        return true
                    }
				],
                account_number: [
                    (value) => {
                        if(!value) {
                            return "Account number is required"
                        }

                        if(this.object.bank) {
                            var bank = this.banks.filter(item => item.value === this.object.bank)[0]
                            if(bank.account_digits.indexOf(value.length.toString()) === -1){
                                return 'Branch code must ' + bank.account_digits.join(' or ') + ' characters'
                            }
                        }

                        if(!/^\d+$/.test(value)){
                            return 'Account number must be numeric value'
                        }

                        return true
                    }
                ],
                cpf_choice: [
                    (value) => !!value || "CPF Choice is required"
                ],
                shirt_size: [
                    (value) => !!value || "Shift size is required"
                ],
                dol: [
                    (value) => {
                        if(this.object.employment_status === 'L' || this.object.employment_status === 'NL'){
                            if(!value){
                                return "Date of resign is required"
                            }
                        }
                        return true
                    }
                ],
                image: [
                    (value) => {
                        if(!value) {
                            return 'Profile picture is required'
                        }

                        var fileSize = Math.ceil(value.size / 1024)

                        if(fileSize > 2048) {
                            return 'Image File must less than 2MB'
                        }

                        return true
                    }
                ]
			},
		}
	},
	computed: {
		form() {
			return this.$refs.form
		},
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
        this.get()
	},
	methods: {
        get: function() {
            this.loading = true
            this.$store.dispatch('epanel/promoter/getPromoter').then((response) => {
                this.permissions = response.data.permissions
                this.loading = false
                if(this.permissions.can_add){
                    this.citizenships = response.data.citizenships
                    this.genders = response.data.genders
                    this.districts = response.data.districts
                    this.banks = response.data.banks
                    this.shirt_sizes = response.data.shirt_sizes
                    this.cpf_choices = response.data.cpf_choices
                    this.agencies = response.data.agencies
                    this.employment_statuses = response.data.employment_statuses
                    this.fg_types = response.data.fg_types
                }
            }).catch(() => {
                this.loading = false
            })
		},
		reset: function() {
            this.loading = false
            this.object = {}
            this.errors = {}
            this.form.reset()
			this.form.resetValidation()
		},
		updateBranchCode: function(index) {
            const bank = index?this.banks.filter(item => item.value === index)[0]:false
            this.isDisabledBranch = bank && ["DBS", "POSB", "OCBC", "UOB"].indexOf(bank.code) > -1?true:false
		},
		getMinDOBDate: function() {
            return makeDate(new Date().getFullYear() - 80, 1, 1)
        },
        getMaxDOBDate: function() {
            return makeDate(new Date().getFullYear() - 16, 12, 31)
        },
        getMaxDOPRDate: function() {
            return today()
        },
        readFileData: function(file) {
            if(!file){
                return
            }

            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.addEventListener('load', (e) => {
                this.object.image = e.target.result
            })
        },
        create: function() {
			if(this.form.validate()){
				this.loading = true
				var object = copyObject(this.object)
				object['nric'] = encodeBase64(this.object.nric)
				object['branch_code'] = encodeBase64(this.object.branch_code)
				object['account_number'] = encodeBase64(this.object.account_number)

				this.$store.dispatch('epanel/promoter/createPromoter', object).then((response) => {
                    this.openNotifier(response.message)
                    this.reset()
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
		},
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        }
	}
}

</script>